import {
  CountryListRoute,
  AirportListRoute,
  UserListRoute,
  AirlineListRoute,
  CurrencyListRoute,
  CityListRoute,
  TimeTableTemplateParseRoute,
  AirBookingTemplateParseRoute,
  TimeTableTemplateListRoute,
  CounterpartyListRoute,
  AirBookingListRoute,
  AirplaneListRoute,
  TripListRoute,
  AirBookingReportRoute,
  TripItemListRoute,
  AirBookingTemplateListRoute,
  AirBookingCreateRoute,
  OfferListRoute,
  CertListRoute,
  DebtorCompareRoute,
  RacoonRoute,
  RacoonListRoute,
  ReviseItemListRoute,
  TripUsersRoute,
  ReviseSortRoute,
  ParserCheckRoute,
  ParserCheckListRoute,
  ParsersCheckRoute,
  ParsersCheckListRoute,
  RacoonTicketParser,
  CreditCardsRoute,
  RailListRoute,
  TouristServicesRoute,
  TouristServicesListRoute,
  RacoonFastRoute,
  SalesRegisterRoute,
  SalesRegisterListRoute,
  CounterpartiesFinderRoute,
  DocumentsListRoute,
  CounterpartiesContacts,
  PaxActivityRoute,
  TuesdayRoute
} from "./routesConstants";
import _ from "lodash";
import ReviseItemListPage from "./racoon/pages/item/reviseItemListPage";
import ReviseListPage from "./racoon/pages/revise/reviseListPage";

export default user => {
  const renderTours = () =>{
    const res = [
      { section: "Туры" },
      {
        title: "Поездки",
        bullet: "dot",
        root: true,
        page: TripListRoute.slice(1),
        icon: "flaticon2-photograph",
      },
      {
        title: "Предложения",
        bullet: "dot",
        root: true,
        icon: 'flaticon2-contract',
        page: OfferListRoute.slice(1)
      },
      {
        title: "Элементы",
        bullet: "dot",
        root: true,
        icon: 'flaticon2-hospital',
        page: TripItemListRoute.slice(1)
      },
      {
        title: "Пользователи",
        bullet: "dot",
        root: true,
        icon: "flaticon2-calendar-3",
        page: TripUsersRoute.slice(1)
      },
    ];
    return res
  }
  const getDataLinks = () => {
    const res = [];
    if (
      user &&
      user.roles &&
      (user.roles.indexOf("admin") !== -1 ||
        user.roles.indexOf("superAdmin") !== -1)
    ) {
      res.push({
        title: "Пользователи",
        root: true,
        bullet: "dot",
        page: UserListRoute.slice(1),
        icon: "flaticon2-user"
      });
      res.push({
        title: "Валюты",
        root: true,
        bullet: "dot",
        page: CurrencyListRoute.slice(1),
        icon: "flaticon-price-tag"
      });
    }
    if (
      user.email === "ykarmakov@gmail.com" ||
      user.email === "unoegohh@gmail.com"
    ) {
      res.push({
        title: "Сравнение должников",
        root: true,
        bullet: "dot",
        page: DebtorCompareRoute.slice(1),
        icon: "flaticon2-user"
      });
    }
    return res;
  };
  if(user &&
    user.roles &&
    user.roles.indexOf("employee") === -1 && user.roles.indexOf("toursUser") !== -1
  ){
    return {aside:
    {
      self: {},
      items: [
        ...renderTours(),
        { section: "Услуги" },
        {
          title: "Туристические услуги",
          bullet: "dot",
          root: true,
          page: TouristServicesListRoute.slice(1),
          icon: 'flaticon2-gift-1'
        },

      ]
    }}
  }
  return {
    aside: {
      self: {},
      items: [
        {
          title: "Главная",
          root: true,
          icon: "flaticon2-architecture-and-city",
          page: "",
          bullet: "dot"
        },
        {
          title: "Задачи",
          bullet: "dot",
          root: true,
          page: TuesdayRoute.slice(1),
          icon: 'flaticon-calendar-with-a-clock-time-tools'
        },
        { section: "Бронирования" },
        {
          title: "Загрузить",
          bullet: "dot",
          icon: "flaticon-event-calendar-symbol",
          root: true,
          page: AirBookingCreateRoute.slice(1)
        },
        {
          title: "Список",
          desc: "Список авиабронирований",
          bullet: "dot",
          root: true,
          page: AirBookingListRoute.slice(1),
          icon: 'flaticon2-list-1'
        },
        {
          title: "Отчет",
          bullet: "dot",
          root: true,
          page: AirBookingReportRoute.slice(1),
          icon: 'flaticon2-infographic'
        },
        {
          title: "ЖД",
          bullet: "dot",
          root: true,
          page: RailListRoute.slice(1),
          icon: 'flaticon2-lorry'
        },
        {
          title: "Туристические услуги",
          bullet: "dot",
          root: true,
          page: TouristServicesListRoute.slice(1),
          icon: 'flaticon2-gift-1'
        },
        {
          title: "Активность туриста",
          bullet: "dot",
          root: true,
          page: PaxActivityRoute.slice(1),
          icon: 'flaticon-calendar-with-a-clock-time-tools'
        },
        { section: "Парсеры" },
        {
          title: "Парс авиа",
          root: true,
          page: AirBookingTemplateParseRoute.slice(1),
          icon: "flaticon2-reload",
        },
        {
          title: "Парс расписания",
          root: true,
          page: TimeTableTemplateParseRoute.slice(1),
          icon: "flaticon2-refresh",
        },
        {
          title: "Разное",
          root: true,
          bullet: "dot",
          page: '',
          icon: "flaticon-more",
          submenu: [
            {
              title: "Парс ошибок",
              bullet: "dot",
              page: ParsersCheckListRoute.slice(1),
            },
            {
              title: "Шаблон авиа бронирования",
              bullet: "dot",
              page: AirBookingTemplateListRoute.slice(1)
            },
            {
              title: "Шаблон расписания",
              bullet: "dot",
              page: TimeTableTemplateListRoute.slice(1)
            }
          ]
        },
        ...renderTours(),
        { section: "Данные" },
        {
          title: "Локации",
          root: true,
          bullet: "dot",
          page: "",
          icon: "flaticon2-map",
          submenu: [
            {
              title: "Страны",
              desc: "Список стран",
              bullet: "dot",
              page: CountryListRoute.slice(1)
            },
            {
              title: "Города",
              desc: "Список стран",
              bullet: "dot",
              page: CityListRoute.slice(1)
            },
            {
              title: "Аэропорты",
              bullet: "dot",
              page: AirportListRoute.slice(1)
            }
          ]
        },
        {
          title: "Авиа",
          root: true,
          bullet: "dot",
          page: '',
          icon: "flaticon2-paper-plane",
          submenu: [
            {
              title: "Авиакомпании",
              root: true,
              bullet: "dot",
              page: AirlineListRoute.slice(1),
            },
            {
              title: "Самолеты",
              root: true,
              bullet: "dot",
              page: AirplaneListRoute.slice(1),
            },
          ]
        },
        {
          title: "Контрагенты",
          root: true,
          bullet: "dot",
          page: '',
          icon: "flaticon-rocket",
          submenu: [
            {
              title: "Контрагенты",
              root: true,
              bullet: "dot",
              page: CounterpartyListRoute.slice(1),
            },
            {
              title: "Контакты",
              root: true,
              bullet: "dot",
              page: CounterpartiesContacts.slice(1),
            },
            {
              title: "Пассажир-Контрагент",
              root: true,
              bullet: "dot",
              page: CounterpartiesFinderRoute.slice(1),
            },
          ]
        },
        {
          title: "Документы",
          root: true,
          bullet: "dot",
          page: DocumentsListRoute.slice(1),
          icon: "flaticon-interface-3"
        },
        {
          title: "Сверки",
          root: true,
          bullet: "dot",
          page: "",
          icon: "flaticon2-layers",
          submenu: [
            {
              title: "Отчеты",
              bullet: "dot",
              page: ReviseSortRoute.slice(1)
            },
            // {
            //   title: "По выписаным билетам",
            //   bullet: "dot",
            //   page: RacoonTicketParser.slice(1)
            // },
            {
              title: "Быстрая Сверка",
              bullet: "dot",
              page: RacoonFastRoute.slice(1)
            },
          ]
        },
        {
          title: "Реестр продаж",
          bullet: "dot",
          root: true,
          page: SalesRegisterListRoute.slice(1),
          icon: 'flaticon2-line-chart'
        },
        ...getDataLinks(),
      ]
    }
  };
};
