export const LocationsRoute = "/locations";
export const CountrySlug = "/countries";
export const CountryRoute = LocationsRoute + CountrySlug;
export const CountryListRoute = CountryRoute + "/";
export const CountryCreateRoute = CountryRoute + "/create";
export const CountryEditRoute = CountryRoute + "/edit/";

export const AirRoute = "/air";
export const AirlineRoute = AirRoute + "/airlines";
export const AirlineListRoute = AirlineRoute + "/";
export const AirlineCreateRoute = AirlineRoute + "/create";
export const AirlineEditRoute = AirlineRoute + "/edit/";

export const AirplaneRoute = AirRoute + "/airplane";
export const AirplaneListRoute = AirplaneRoute + "/";
export const AirplaneEditRoute = AirplaneRoute + "/edit/";
export const AirplaneCreateRoute = AirplaneRoute + "/create";
export const RailRoute = "/railways";
export const RailListRoute = RailRoute + "/list";
export const RailCreateRoute = RailRoute + "/create";
export const RailEditRoute = RailRoute + "/edit/";
export const TouristServicesRoute = "/touristServices";
export const TouristServicesListRoute = TouristServicesRoute + "/list";
export const TouristServicesCreateRoute = TouristServicesRoute + "/create";
export const TouristServicesEditRoute = TouristServicesRoute + "/edit/";

export const UsersRoute = "/users";
export const UserListRoute = UsersRoute + "/list";
export const UserCreateRoute = UsersRoute + "/create";
export const UserEditRoute = UsersRoute + "/edit/";

export const CurrencyRoute = "/currency";
export const CurrencyListRoute = CurrencyRoute + "/";
export const CurrencyCreateRoute = CurrencyRoute + "/create";
export const CurrencyEditRoute = CurrencyRoute + "/edit/";

export const CertRoute = "/cert";
export const CertListRoute = CertRoute + "/";
export const CertCreateRoute = CertRoute + "/create";
export const CertEditRoute = CertRoute + "/edit/";

export const CityRoute = LocationsRoute + "/city";
export const CityListRoute = CityRoute + "/";
export const CityCreateRoute = CityRoute + "/create";
export const CityEditRoute = CityRoute + "/edit/";

export const AirportRoute = LocationsRoute + "/airport";
export const AirportListRoute = AirportRoute + "/";
export const AirportCreateRoute = AirportRoute + "/create";
export const AirportEditRoute = AirportRoute + "/edit/";

export const CounterpartyRoute = "/counterparty";
export const CounterpartyListRoute = CounterpartyRoute + "/";
export const CounterpartyCreateRoute = CounterpartyRoute + "/create";
export const CounterpartyEditRoute = CounterpartyRoute + "/edit/";
export const CounterpartiesFinderRoute = CounterpartyRoute + "/counterpartiesFinder";
export const CounterpartiesContacts = CounterpartyRoute + "/contacts";

export const CreditCardsRoute = "/creditCards";

export const DocumentsRoute = "/documents";
export const DocumentsListRoute = DocumentsRoute + "/list";
export const DocumentsCreateRoute = DocumentsRoute + "/create";
export const AirBookingRoute = AirRoute + "/airBooking";
export const AirBookingListRoute = AirBookingRoute + "/list";
export const AirBookingCreateRoute = AirBookingRoute + "/create";
export const AirBookingEditRoute = AirBookingRoute + "/edit/";
export const AirBookingReportRoute = AirBookingRoute + "/report";

export const ParsersRoute = "/parsers";

export const AirBookingTemplateRoute = ParsersRoute + "/airBooking";
export const AirBookingTemplateListRoute = AirBookingTemplateRoute + "/list";
export const AirBookingTemplateCreateRoute =
  AirBookingTemplateRoute + "/create";
export const AirBookingTemplateEditRoute = AirBookingTemplateRoute + "/edit/";
export const AirBookingTemplateParseRoute = AirBookingTemplateRoute + "/parse";

export const TimeTableTemplateRoute = ParsersRoute + "/timeTable";
export const TimeTableTemplateListRoute = TimeTableTemplateRoute + "/list";
export const TimeTableTemplateCreateRoute = TimeTableTemplateRoute + "/create";
export const TimeTableTemplateEditRoute = TimeTableTemplateRoute + "/edit/";
export const TimeTableTemplateParseRoute = TimeTableTemplateRoute + "/parse";

export const ParsersCheckRoute = ParsersRoute + "/check";
export const ParsersCheckListRoute = ParsersCheckRoute + "/list";
export const ParsersCheckEditRoute = ParsersCheckRoute + "/edit/";
export const ParsersCheckCreateRoute = ParsersCheckRoute + "/create";

export const TripsRoute = "/trips";

export const TripRoute = TripsRoute + "/trip";
export const TripListRoute = TripRoute + "/";
export const TripCreateRoute = TripRoute + "/create";
export const TripEditRoute = TripRoute + "/edit/";

export const OfferRoute = TripsRoute + "/offer";
export const OfferListRoute = OfferRoute + "/";
export const OfferCreateRoute = OfferRoute + "/create";
export const OfferEditRoute = OfferRoute + "/edit/";

export const TripUsersRoute = TripsRoute + "/users";
export const TripUserCreateRoute = TripUsersRoute + "/create";
export const TripUserEditRoute = TripUsersRoute + "/edit/";

export const TripItemRoute = TripsRoute + "/items";
export const TripItemListRoute = TripItemRoute + "/";
export const TripItemCreateRoute = TripItemRoute + "/create";
export const TripItemEditRoute = TripItemRoute + "/edit/";

export const AdminRoutes = "/admin";
export const DebtorRoutes = AdminRoutes + "/debtor";
export const DebtorCompareRoute = DebtorRoutes + "/compare";

export const RacoonRoute = "/racoon";
export const RacoonListRoute = RacoonRoute + "/";
export const RacoonAruanaRoute = RacoonRoute + "/aruana";
export const RacoonAruanaKayarRoute = RacoonRoute + "/aruanaKayar";
export const RacoonSilkKayarRoute = RacoonRoute + "/silkKayar";
export const RacoonAruanaIataRoute = RacoonRoute + "/aruanaIata";
export const RacoonFastRoute = RacoonRoute + "/fast";

export const RacoonAruanaGoogleRoute = RacoonRoute + "/aruanaGoogle";
export const RacoonKayarGoogleRoute = RacoonRoute + "/kayarGoogle";


export const RacoonTicketParser = RacoonRoute + '/ticketParser'

export const ReviseItemRoute = RacoonRoute + "/revise/item";
export const ReviseItemListRoute = ReviseItemRoute + "/";
export const ReviseItemGetRoute = ReviseItemRoute + "/get/";

export const ReviseRoute = RacoonRoute + "/revise";
export const ReviseListRoute = ReviseRoute + "/";
export const ReviseGetRoute = ReviseRoute + "/get/";
export const ReviseSortRoute = ReviseRoute + "/sort";

export const SalesRegisterRoute = "/salesRegister";
export const SalesRegisterListRoute =SalesRegisterRoute+ "/list";
export const SalesRegisterCreateRoute =SalesRegisterRoute+ "/create";
export const SalesRegisterEditRoute =SalesRegisterRoute+ "/edit/";

export const PaxActivityRoute = "/paxActivity";

export const TuesdayRoute = "/tuesday";
