import React, { useState, useEffect } from "react";
import ApiSelect from "./apiSelect";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { LoadCurrenciesAction } from "../../../../redux/actions/misc";
import Select from "react-select";
import { useController } from "react-hook-form";
import _ from "lodash";

const CurrencyPicker = ({
  value,
  name,
  formController,
  currencies,
  label,
  key,
  LoadCurrenciesAction,
  errors,
  className
}) => {
  useEffect(() => {
    if (!currencies.length) {
      LoadCurrenciesAction();
    }
  }, []);

  const {
    field: { onChange, value: currValue },
    fieldState
  } = useController({
    name,
    control: formController.control,
    rules: { required: true },
    defaultValue: currencies.filter(option => option.value === value)
  });
  return (
    <div style={{position: 'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      <Select
        options={currencies}
        key={key}
        value={currencies.filter(option => option.value === currValue)}
        defaultValue={currencies.filter(option => option.value === value)}
        error={!!fieldState.error}
        onChange={newVal => {
          console.log(newVal);
          onChange(newVal.value);
          // formController.setValue ( name , newVal.value , { shouldDirty : true } )
        }}
        // getOptionValue={obj => obj.value === currValue}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: _.get(errors, name) ? '#FD397A' : '#e2e5ec',
          }),
          menu:provided => {
            return {...provided, zIndex: '10000'};
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = ({misc}) => {
  const { currencies } = misc;
  return { currencies };
};

export default connect(mapStateToProps, { LoadCurrenciesAction })(
  CurrencyPicker
);
