import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import SearchSelect from "../../../components/searchSelect";
import {Controller, useController, useWatch} from "react-hook-form";
import _ from 'lodash'
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import ApiSelect from "../../base/components/forms/apiSelect";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import ModalCityForm from "./ModalCityForm";
import { v4 as uuidv4 } from 'uuid';
import UploadDocuments from "./uploadDocuments";
import ParseInput from "../../air/airBooking/components/parseInput";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import moment from "moment";
import Button from "react-bootstrap/Button";
import numberFormat from "../../../helpers/numberFormat";

const fieldsList= [
  {label:'Маршрут', inputName:'route', type:'text', defaultValue:''},
  {label:'Отель', inputName:'hotel', type:'text', defaultValue:''},
  {label:'Тип питания', inputName:'typeMeal', type:'text', defaultValue:''},
  {label:'Тип номера', inputName:'typeRoom', type:'text', defaultValue:''},
  {label:'Количество ночей', inputName:'nights', type:'number', defaultValue:0},
  {label:'Комментарий услуги', inputName:'comment', type:'text', defaultValue:''},
  {label:'Туристический пакет', inputName:'touristPack', type:'text', defaultValue:''},
  {label:'Экскурсия', inputName:'excursion', type:'text', defaultValue:''},
  {label:'Перевозчик', inputName:'carrier', type:'carrier'},
  {label:'БСО', inputName:'ticket', type:'text', defaultValue:''},
  {label:'Количество дней', inputName:'days', type:'number', defaultValue:0},
  {label:'Тип визы', inputName:'typeVisa', type:'select', options:['Гостевая', 'Служебная', 'Деловая', 'Туристическая']},
  {label:'Кратность визы', inputName:'multiplicityVisa', type:'select', options:['Однократная', 'Двухкратная', 'Трехкратная', 'Многократная']},
  {label:'Страховая сумма', inputName:'insuranceSum', type:'number', defaultValue:0},
  {label:'Вид прочих', inputName:'typeOther', type:'text', defaultValue:''},
  {label:'Номенклатура', inputName:'nomenclature', type:'text', defaultValue:''},
  {label:'Город', inputName:'city', type:'city'},
  {label:'Срочность', inputName:'urgency', type:'select', options:['Сроная', 'НеСрочная']}
]

const ServiceItem = ({prefix,
                       types,
                       formController,
                       serv,
                       deleteService,
                       copy,
                       countries,
                       edit,
                       update,
                       suppliers,
                       fromParser,
                       setTempValue,
                       nameCount}) => {
  const {control, register, watch, formState: {errors}, getValues, setValue} = formController
  const {
    field: { onChange: typeOnChange, value: typeValue }
  } = useController({
    name: `${prefix}.type`,
    rules: { required: true },
    control,
    defaultValue: ''
  });
  const {
    field: { onChange: supplierOnChange, value: supplierValue }
  } = useController({
    name: `${prefix}.supplier`,
    control,
    rules: { required: true },
    defaultValue: ''
  });
  const {
    field: { onChange: countryOnChange, value: countryValue }
  } = useController({
    name: `${prefix}.country`,
    control,
    rules: { required: true },
    defaultValue: ''
  });

  const getParam = (name) =>{
    return +_.get(getValues(), `${prefix}.${name}`) || 0
  }
  const totalSumm = () =>{
    return (getParam('summKZT')+getParam('krsKayar')+getParam('commission')-getParam('discount'))*getParam('quantity')
  }
  useEffect(() => {
    if(getParam('summCurrency')!==0){
      const sumKzt=+(getParam('summCurrency')*getParam('exchangeRate')).toFixed(2)

      setValue(`${prefix}.summKZT`, sumKzt)
    }
  }, [watch([`${prefix}.summCurrency`,`${prefix}.exchangeRate`,`${prefix}.krsKayar`,`${prefix}.commission`,`${prefix}.discount`,`${prefix}.quantity`])]);

  useEffect(() => {
    const nights = _.get(getValues(), `${prefix}.optionalFields.nights`)
    const startDate =_.get(getValues(), `${prefix}.startDate`)
    const endDate = _.get(getValues(), `${prefix}.endDate`)
    const type =_.get(getValues(), `${prefix}.type`)
    if(startDate && endDate && type && nights && !edit){
      const diffDate = moment.duration(moment(endDate,'DD.MM.YYYY').diff(moment(startDate,'DD.MM.YYYY'))).asDays()
      setValue(`${prefix}.optionalFields.nights`, diffDate)
    }
  }, [useWatch({control,name:[`${prefix}.startDate`,`${prefix}.endDate`,`${prefix}.type`]})]);
  useEffect(() => {
    if(!fromParser && !edit && getValues().issuedWithoutDocs){
      console.log('list', suppliers)
      console.log('env', process.env.REACT_APP_DEFAULT_WITHOUTDOC_SUPPLIER)
      setValue(`${prefix}.supplier`,process.env.REACT_APP_DEFAULT_WITHOUTDOC_SUPPLIER)
    }
  }, [watch([`issuedWithoutDocs`])]);
  useEffect(() => {
    if(
      _.get(getValues(), `${prefix}.supplier`) === process.env.REACT_APP_DEFAULT_WITHOUTDOC_SUPPLIER
      && !getValues().issuedWithoutDocs &&!edit){
      setValue(`issuedWithoutDocs`, true)
    }
  }, [useWatch({control,name:`${prefix}.supplier`})]);
  useEffect(() => {
    const currencyId = _.get(getValues(), `${prefix}.currency`)
    const exchangeRateNow = _.get(getValues(), `${prefix}.exchangeRate`)
    if(currencyId && !edit){
      if((process.env.REACT_APP_DEFAULT_CURRENCY !== currencyId)&& !fromParser ){
        axios.post('/api/currencies/exchangeRateByDate',{
          currencyId,
          date: getValues().issueDate})
          .then(({data})=>{
            console.log('axios')
            setValue(`${prefix}.exchangeRate`,data.exchangeRate)
          })
          .catch(sayError)
      } else if(process.env.REACT_APP_DEFAULT_CURRENCY === currencyId && exchangeRateNow !== 1){
        setValue(`${prefix}.exchangeRate`,1)
      }
    }

  }, [useWatch({control, name:[`${prefix}.currency`,'issueDate']})]);
  useEffect(() => {

  }, []);
  if(!countries){
    return 'loading...'
  }

  const renderOptionalInputs = () =>{
    if(typeValue === ''){
      return <p>
        Пожалуйста выберите тип услуги для генерации дополнительных полей
      </p>
    } else{
      const neededInfo = _.find(types, {id:typeValue})
      if(neededInfo.fields.length === 0){
        return <p>Дополнительных полей не требуется</p>
      }else{
        return neededInfo.fields.map((label,index)=>{
          const input = _.find(fieldsList, {label})
          if(input.type === 'select'){
            return <FormHookSelect
              label={input.label}
              register={register(`${prefix}.optionalFields.${input.inputName}`)}
              errors={errors}
            >
              {input.options.map((o,i)=> {
                return <option key={i} value={o}>{o}</option>
              })}
            </FormHookSelect>
          }else if(input.type === 'city'){
            return <div className={'d-flex align-items-center justify-content-between'}><Controller
              control={control}
              render={({ field: { onChange, onBlur, value:cityValue } }) => (
                <ApiSelect
                  value={cityValue}
                  label={'Город'}
                  url={"/api/locations/cities/searchInput"}
                  style={{width:'90%'}}
                  cb={newVal => onChange(newVal)}
                />
              )}
              name={`${prefix}.optionalFields.city`}
            />
              <ModalCityForm changeValue={(id)=>{setValue(`${prefix}.optionalFields.city`,id)}}/>
            </div>
          }else if(input.type === 'carrier'){
            return <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <ApiSelect
                  value={value}
                  label={'Перевозчик(Контрагент)'}
                  url={"/api/counterparty/searchInput"}
                  queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
                  cb={newVal => onChange(newVal)}
                />
              )}
              name={`${prefix}.optionalFields.carrier`}
            />
          } else {
            return <div className={'mb-3'} key={index}>
              <FormHookInput
                register={register(`${prefix}.optionalFields.${input.inputName}`)}
                label={input.label}
                type={input.type}
                defaultValue={input.defaultValue}
                errors={errors}
              />
            </div>
          }
        })
      }
    }
  }
  const renderLabel = () =>{
    return <>Индивидуальный код <i className="fa fa-barcode ml-2"
                                   onClick={()=>{setValue(`${prefix}.idInOneC`, uuidv4())}}
                                   style={{cursor:'pointer'}} /></>
  }
  return (
    <>
      <Row>
        <Col xs={8}>
          <Row className={'mb-3'}>
            <Col >
              <FormHookSelect
                defaultValue={supplierValue}
                register={register(`${prefix}.supplier`, { required: true })}
                errors={errors}
                label={'Поставщик'}
              >
                {suppliers?.map(u=>{
                  return <option value={u.value}>{u.label}</option>
                })}
              </FormHookSelect>
            </Col>
            {/*<Col>*/}
            {/*  <ApiSelect*/}
            {/*    value={supplierValue}*/}
            {/*    label={'Поставщик'}*/}
            {/*    hasError={_.get(errors, `${prefix}.supplier`)}*/}
            {/*    url={"/api/counterparty/searchInput"}*/}
            {/*    queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}*/}
            {/*    cb={newVal => supplierOnChange(newVal)}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col>
              <FormHookInput
                register={register(`${prefix}.idInOneC`,)}
                label={renderLabel()}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormHookInput
                register={register(`${prefix}.quantity`, {required: true,})}
                label={'Кол-во услуг'}
                type={'number'}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col>
              <FormHookInput
                onChange={e => {
                  e.target.value = ("" + e.target.value).toUpperCase();
                }}
                inputstyle={{"text-transform": "uppercase"}}
                register={register(`${prefix}.mainPax`, {
                  required: true,
                  onChange: e => {
                    e.target.value = ("" + e.target.value).toUpperCase();
                  }
                })}
                label={'Основной пассажир'}
                errors={errors}
              />
            </Col>
            <Col xs={2}>
              <FormHookInput
                register={register(`${prefix}.totalpaxes`, {required: true,})}
                label={'Всего ч.'}
                type={'number'}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col>
              <CustomDatePicker formController={formController}
                                value={serv.startDate}
                                name={`${prefix}.startDate`}
                                rules={{required: true}}
                                label={'Дата начала'}/>
            </Col>
            <Col>
              <CustomDatePicker formController={formController}
                                value={serv.endDate}
                                name={`${prefix}.endDate`}
                                rules={{required: true}}
                                label={'Дата окончания'}/>
            </Col>
            <Col xs={5}>
              <SearchSelect
                onChange={countryOnChange}
                label={'Страна'}
                hasError={_.get(errors, `${prefix}.country`)}

                defaultValue={countryValue}
                array={countries}/>
              {/*<ApiSelect*/}
              {/*  value={countryValue}*/}
              {/*  label={'Страна'}*/}
              {/*  hasError={_.get(errors, `${prefix}.country`)}*/}
              {/*  url={"/api/locations/countries/searchInput"}*/}
              {/*  cb={newVal => countryOnChange(newVal)}*/}
              {/*/>*/}
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col>
              <CurrencyPicker
                value={serv.currency}
                key={serv.id}
                formController={formController}
                label={'Валюта'}
                name={`${prefix}.currency`}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormHookInput
                register={register(`${prefix}.exchangeRate`, {required: true,})}
                label={'Курс'}
                step={"0.01"}
                type={'number'}
                errors={errors}
              />
            </Col>
            <Col>
              <FormHookInput
                register={register(`${prefix}.summCurrency`, {required: true,})}
                label={'Сумма Вал.'}
                step={"0.01"}
                type={'number'}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col>
              <FormHookInput
                register={register(`${prefix}.summKZT`, {required: true,})}
                label={'СуммаKZT'}
                type={'number'}
                step={"0.01"}
                errors={errors}
                disabled

              />
            </Col>
            <Col>
              <FormHookInput
                register={register(`${prefix}.commission`, {required: true,})}
                label={'Комиссия'}
                type={'number'}
                step={"0.01"}
                errors={errors}
              />
            </Col>
            <Col>
              {/*<FormHookInput*/}
              {/*  register={register(`${prefix}.discount`, {required: true,})}*/}
              {/*  label={'Скидка'}*/}
              {/*  type={'number'}*/}
              {/*  errors={errors}*/}
              {/*/>*/}
                <FormHookInput
                  register={register(`${prefix}.krsKayar`)}
                  label={'КРС Kayar'}
                  type={'number'}
                  step={"0.01"}
                  errors={errors}
                />
            </Col>

          </Row>
          {/*<Row className={'mb-3'}>*/}
          {/*  <Col xs={4}>*/}
          {/*    <FormHookInput*/}
          {/*      register={register(`${prefix}.krsKayar`)}*/}
          {/*      label={'КРС Kayar'}*/}
          {/*      type={'number'}*/}
          {/*      errors={errors}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col xs={8}>
              <FormHookInput
                register={register(`${prefix}.discription`)}
                label={'Комментарий'}
                errors={errors}
              />
            </Col>
            <Col>
              <div style={{position: 'relative'}}>
                <label className={'absolutLabel'}>Калькулятор КРС</label>
                <Form.Control
                  onBlur={(e) => {
                    const krs = e.target.value - _.get(getValues(), `${prefix}.summKZT`)
                    setValue(`${prefix}.commission`, krs)
                    e.target.value = ''
                  }}
                  disabled={_.get(getValues(), `${prefix}.summKZT`) === 0}
                  type="number"
                />
              </div>
            </Col>
          </Row>
          {serv.technicalCounterparty && <div className={'mb-3'}>Технический контрагент: {serv.technicalCounterparty}</div>}

          <UploadDocuments cb={(v) => {
            setValue(`${prefix}.documents`, v)
          }} docs={serv.documents} folder={'serviceDocs'}/>
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={serv.canceled}
              {...register(`${prefix}.canceled`)}
              type={"checkbox"}
            />{" "}
            <div className={'text-danger'}>Отменен</div>
          </Form.Label>

          <h6 className={'mt-3'}>Итоговая стоимость: {numberFormat(totalSumm())} KZT</h6>
        </Col>
        <Col>
          <Button onClick={()=>setTempValue()}
                  disabled={!nameCount}
                  className={'w-100'}
                  variant={'outline-primary'}>Заполнить шаблон</Button>
          {!nameCount && <div >Выберите покупателя</div>}
          {!edit && <div className={'mt-3'}><ParseInput url={'api/touristServices/rateHawk/parse'}
                                                        keyValue={'text'}
                                                        setter={update}
                                                        placeholder={'Парс тур услуги'}/></div> }
          <div className={'mb-3 mt-3'}>
          <SearchSelect label={'Тип услуги'}
                          onChange={typeOnChange}
                          hasError={_.get(errors, `${prefix}.type`)}
                          defaultValue={typeValue}
                          errors={errors}
                          array={types.map(t => ({label: t.name, value: t.id}))}/>
          </div>

          {renderOptionalInputs()}

        </Col>

      </Row>
      <div className={'d-flex justify-content-between'}>
        <div onClick={copy} className={'text-primary'} style={{cursor:'pointer'}}>
          Скопировать услугу
        </div>
        <div className={'text-danger mt-2'}
                      onClick={() => deleteService()}
                      style={{cursor: 'pointer', textAlign: 'end', width: 'max-content'}}>
          Удалить услугу <i className={"fa fa-trash"}/>
        </div>

      </div>
      {serv.info && serv.info.map((info,i)=>{
        return <div className={'text-danger mt-2'} key={i}>{info}</div>
      })}
    </>

  );
};

export default ServiceItem;