import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import DocumentsForm from "../../documents/components/documentsForm";
import Loader from "../../../components/loader";
import {useSelector} from "react-redux";
import TuesdayForm from "./TuesdayForm";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";

const TuesdayTempData = {
  name:'',
  users:[],
  taskDays:[],
  taskTimes:[]
}

const CreateEditModal = ({children,id, reload,usersList}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(show && id){
      axios.get('/api/tuesday/'+id)
        .then(({data})=>{
          console.log('task',data)
          setData({...data.task, taskDays:data.task.taskDays.map(d=>(`${d}`))})
        })
        .catch(sayError)
    }else if(show){
      setData(TuesdayTempData)
    }else{
      setData()
    }
  }, [show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = (data) =>{
    setLoading(true)
    const options = {
      method: id ? 'patch' : 'put',
      url: `/api/tuesday/${!!id ? id : ''}`,
      data
      }
    console.log('submit', data)
    axios(options)
      .then(()=>{
        reload()
        handleClose()
    })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }

  const removeTask = () =>{
    if(window.confirm('Вы уверены, что хотите удалить задачку?')){
      axios.delete('/api/tuesday/'+id)
        .then(()=>{
          setLoading(true)
          handleClose()
          reload()
          setData()
        })
        .catch(sayError)
    }
  }
  return (
    <div>
      <div onClick={handleShow} className={'cN'}>{children}</div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? 'Редактировать задачу':'Создать задачу'}</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={!data || !usersList || loading}>
          <Modal.Body >
            {data && usersList &&  <TuesdayForm data={data}
                                                onSubmit={onSubmit}
                                                removeTask={id ? removeTask : undefined}
                                                usersList={usersList}/>}
          </Modal.Body>
        </BlockUi>
      </Modal>

    </div>
  );
};

export default CreateEditModal;