import React, {useEffect, useState} from 'react';
import {Controller, useController, useForm} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import _ from "lodash";
import ApiSelect from "../../base/components/forms/apiSelect";
import Button from "react-bootstrap/Button";
import SearchSelect from "../../../components/searchSelect";
import {Form} from "react-bootstrap";
import moment from "moment";



const TuesdayForm = ({data,onSubmit,usersList,removeTask}) => {
  const formController = useForm({defaultValues: data});
  const {register,
    control,
    getValues,
    handleSubmit,
    formState:{errors}} = formController;
  useEffect(() => {

  }, []);
   const weekArray = [1,2,3,4,5,6,7]
  const hoursArray = Array.from({ length: 24 }, (n, i) => {
    const d = new Date(0, 0, 0, 0, 60 * i);
    return d.toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' });
  })
  console.log('hoursArray',hoursArray)
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'d-flex flex-column gap-3'}>
      <FormHookInput
        register={register(`name`, {required: true})}
        label={'Задача'}
        defaultValue={data.name}
        errors={errors}
      />
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        defaultValue={data.users}
        render={({field: {onChange, onBlur, value}}) => (
          <>
            <SearchSelect
              onChange={(v) => {
                onChange(v)
              }}
              defaultValue={value}
              isMulti={true}
              hasError={_.get(errors, 'users')}
              label={'Ответственные'}
              array={usersList}/>
          </>
        )}
        name={`users`}
      />
      <div>
        <div className={'mb-1'}>Расписание</div>
        <div className={'d-flex justify-content-between'}>
          {weekArray.map((d, i) => {
            return <Form.Label className={'d-flex flex-column align-items-center gap-2 text-capitalize'} key={i}>
              <input {...register("taskDays")} type="checkbox" value={`${d}`}/>
              {moment(d, 'E').locale('ru').format('ddd')}
            </Form.Label>

          })}
        </div>
      </div>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        defaultValue={data.taskTimes}
        render={({field: {onChange, onBlur, value}}) => (
          <>
            <SearchSelect
              onChange={(v) => {
                onChange(v)
              }}
              defaultValue={value}
              isMulti={true}
              hasError={_.get(errors, 'taskTimes')}
              label={'Время напоминания'}
              array={hoursArray.map(h=>({value:h, label:h}))}/>
          </>
        )}
        name={`taskTimes`}
      />
      <div className={'d-flex gap-2 justify-content-between'}>
        {!!removeTask ? <Button type={'button'} variant={'outline-danger'} onClick={removeTask}>Удалить</Button>:<div/>}


        <Button type={'submit'}>Сохранить</Button>
      </div>

    </form>
  );
};

export default TuesdayForm;

