import React from 'react';
import DateToFromPicker from "../../touristServices/components/dateToFromPicker";
import SearchSelect from "../../../components/searchSelect";
import {Form} from "react-bootstrap";

const ListQueryForm = ({query, setQuery}) => {
  return (
    <div className={'d-flex gap-2'}>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>Имя</label>
        <Form.Control
          name={'pax'}
          defaultValue={query.pax}
          onChange={(e) => setQuery(q => ({...q, pax: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>Бронирование</label>
        <Form.Control
          name={'bookingNumber'}
          defaultValue={""}
          onChange={(e) => setQuery(q => ({...q, bookingNumber: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>№ Билета</label>
        <Form.Control
          name={'ticketNumber'}
          defaultValue={""}
          onChange={(e) => setQuery(q => ({...q, ticketNumber: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{width:220}}>
        <DateToFromPicker label={'Дата выписки с - по'} setOptions={(v) => {
          setQuery(q => ({...q, issueDate: v}))
        }}/>
      </div>

      <div style={{width: 100}}>
        <SearchSelect
          onChange={(v) => {
            setQuery(q => ({...q, issuedWithoutDocs: v}))
          }}
          defaultValue={""}
          label={"Документы"}
          array={[{value: '', label: 'Все'}, {value: false, label: 'С док.'}, {
            value: true,
            label: 'Без'
          }]}/>
      </div>

    </div>
  );
};

export default ListQueryForm;